var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100 mb3" }, [
    _c("div", { staticClass: "flex flex-column justify-between" }, [
      _c(
        "div",
        { staticClass: "flex justify-between flex-wrap mb4" },
        _vm._l(_vm.socialApps, function (app, key) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !app.commingSoon,
                  expression: "!app.commingSoon",
                },
              ],
              key: key,
              staticClass:
                "flex items-center bg-adori-light-gray br2 ma2 pointer",
              staticStyle: { width: "48%" },
              on: {
                click: function () {
                  return _vm.handleApps(app.name)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "br2 mr2 tc br--left",
                  style: {
                    width: "200px",
                    height: "158px",
                    backgroundColor: app.color,
                  },
                },
                [
                  _c("img", {
                    staticClass: "relative",
                    staticStyle: { top: "25%" },
                    attrs: { src: app.src, alt: "", title: "" },
                  }),
                ]
              ),
              _c("div", { staticClass: "w-100" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: app.commingSoon,
                        expression: "app.commingSoon",
                      },
                    ],
                    staticClass:
                      "bg-adori-red white f6 dib br2 pa1 relative coming-soon",
                    staticStyle: { top: "-55px" },
                  },
                  [_vm._v("\n            COMING SOON\n          ")]
                ),
                _c("div", { staticClass: "white f4 fw1 ml2 mb2" }, [
                  _vm._v(_vm._s(app.title)),
                ]),
                _c(
                  "div",
                  { staticClass: "block-with-text fw1 f5 ml2 silver" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(app.description) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]
          )
        }),
        0
      ),
      _c("br"),
      _c("div", { staticClass: "pl2 pb1 f5 silver" }, [_vm._v("COMING SOON")]),
      _c(
        "div",
        { staticClass: "flex justify-between flex-wrap mb4" },
        _vm._l(_vm.socialApps, function (app, key) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: app.commingSoon,
                  expression: "app.commingSoon",
                },
              ],
              key: key,
              staticClass:
                "flex items-center bg-adori-light-gray br2 ma2 pointer",
              staticStyle: { width: "48%" },
              on: {
                click: function () {
                  return _vm.handleApps(app.name)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "br2 mr2 tc br--left",
                  style: {
                    width: "200px",
                    height: "158px",
                    backgroundColor: app.color,
                  },
                },
                [
                  _c("img", {
                    staticClass: "relative",
                    staticStyle: { top: "25%" },
                    attrs: { src: app.src, alt: "", title: "" },
                  }),
                ]
              ),
              _c("div", { staticClass: "w-100" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: app.commingSoon,
                        expression: "app.commingSoon",
                      },
                    ],
                    staticClass:
                      "bg-adori-red white f6 dib br2 pa1 relative coming-soon",
                    staticStyle: { top: "-55px" },
                  },
                  [_vm._v("\n            COMING SOON\n          ")]
                ),
                _c("div", { staticClass: "white f4 fw1 ml2 mb2" }, [
                  _vm._v(_vm._s(app.title)),
                ]),
                _c(
                  "div",
                  { staticClass: "block-with-text fw1 f5 ml2 silver" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(app.description) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]
          )
        }),
        0
      ),
      _c("br"),
      _c("div", { staticClass: "pl2 pb1 f5 silver" }, [
        _vm._v("PUBLISH YOUR INTERACTIVE AUDIO AS A BLOG"),
      ]),
      _c("div", { staticClass: "flex justify-between flex-wrap mb4" }, [
        _c(
          "div",
          {
            staticClass: "flex items-center bg-adori-light-gray br2 ma2",
            staticStyle: { width: "48%" },
          },
          [
            _c(
              "div",
              {
                staticClass: "bg-black br2 mr2 tc br--left",
                style: { width: "200px", height: "158px" },
              },
              [
                _c("img", {
                  staticClass: "relative",
                  staticStyle: { top: "25%" },
                  attrs: {
                    src: require("@/assets/publish/medium.png"),
                    alt: "",
                    title: "",
                  },
                }),
              ]
            ),
            _vm._m(0),
          ]
        ),
      ]),
      _c("br"),
      _c("div", { staticClass: "pl2 pb1 f5 silver" }, [
        _vm._v("LAUNCH LIVE STREAM"),
      ]),
      _c("div", { staticClass: "flex justify-between flex-wrap mb4" }, [
        _c(
          "div",
          {
            staticClass: "flex items-center bg-adori-light-gray br2 ma2",
            staticStyle: { width: "48%" },
          },
          [
            _c(
              "div",
              {
                staticClass: "bg-black br2 mr2 tc br--left",
                style: { width: "200px", height: "158px" },
              },
              [
                _c("img", {
                  staticClass: "relative",
                  staticStyle: { top: "25%" },
                  attrs: {
                    src: require("@/assets/publish/live.png"),
                    alt: "",
                    title: "",
                  },
                }),
              ]
            ),
            _vm._m(1),
          ]
        ),
      ]),
      _vm.$env.windowOrigin() !== _vm.$env.envConstProd() &&
      _vm.showYoutubeVideo
        ? _c("iframe", {
            attrs: {
              width: "1216",
              height: "684",
              src: "https://www.youtube.com/embed/1sAOsOTIeP8",
              frameborder: "0",
              allow:
                "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
              allowfullscreen: "",
            },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-100" }, [
      _c(
        "div",
        {
          staticClass: "bg-adori-red white f6 dib br2 pa1 relative coming-soon",
          staticStyle: { top: "-46px" },
        },
        [_vm._v("COMING SOON")]
      ),
      _c("div", { staticClass: "white f4 fw1 ml2 mb2" }, [
        _vm._v("Publish to Medium"),
      ]),
      _c("div", { staticClass: "block-with-text fw1 f5 ml2 silver mr2" }, [
        _vm._v(
          "\n            Combine transcript snippets with episode notes and embedded visuals in a post.\n          "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-100" }, [
      _c(
        "div",
        {
          staticClass: "bg-adori-red white f6 dib br2 pa1 relative coming-soon",
          staticStyle: { top: "-46px" },
        },
        [_vm._v("COMING SOON")]
      ),
      _c("div", { staticClass: "white f4 fw1 ml2 mb2" }, [
        _vm._v("Launch live interactive stream"),
      ]),
      _c("div", { staticClass: "block-with-text fw1 f5 ml2 silver pr2" }, [
        _vm._v(
          "\n            Drop interactive visuals as your stream. Auto publish as a podcast later.\n          "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }