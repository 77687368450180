







































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import social_youtube from '@/assets/social-youtube.svg'
import social_instagram from '@/assets/social-instagram.svg'
import social_facebook from '@/assets/social-facebook.svg'
import social_twitter from '@/assets/social-twitter.svg'
import social_medium from '@/assets/social-medium.svg'
import publish_youtube from '@/assets/publish/youtube.png'
import publish_fb from '@/assets/publish/fb.png'
import publish_instagram from '@/assets/publish/instagram.png'
import publish_linkedin from '@/assets/publish/linkedin.png'
import publish_twitter from '@/assets/publish/twitter.png'

@Component
export default class PublishComingSoon extends Vue {
  @Getter networkId!: string

  @Action getIntegrationsSummary!: any

  paramsId!: string
  paramsChannel!: string

  @Watch('networkId')
  async loadTracks() {
    await this.getIntegrationsSummary({ networkId: this.networkId })
  }

  PUBLISHING_CHANNELS = {
    YouTube: [
      {
        id: 1,
        text: 'Publish your interactive audio as a YouTube video',
        src: social_youtube,
        enabled: true,
      },
    ],
    Audiogram: [
      {
        id: 2,
        text: 'Create and publish an interactive snippet as an Audiogram',
        comingSoon: true,
      },
    ],
    'Social Networks': [
      {
        id: 3,
        text: 'Publish to Instagram',
        comingSoon: true,
        src: social_instagram,
      },
      {
        id: 4,
        text: 'Publish to Facebook',
        comingSoon: true,
        src: social_facebook,
      },
      {
        id: 5,
        text: 'Publish to Twitter',
        comingSoon: true,
        src: social_twitter,
      },
      {
        id: 6,
        text: 'Publish to Medium',
        comingSoon: true,
        src: social_medium,
      },
    ],
    'Live stream': [{ id: 8, text: 'Launch a live stream', comingSoon: true }],
  }
  socialApps = [
    {
      id: 2,
      name: 'facebook',
      title: 'Publish to Facebook',
      description: '240 min limit. Recommend a 1 to 5 min snippet in landscape.',
      commingSoon: true,
      color: '#3b5998',
      src: publish_fb,
    },
    {
      id: 3,
      name: 'instagram',
      title: 'Publish to Instagram',
      description: '1 minute limit, portrait only videos.',
      commingSoon: true,
      color: '#3f729b',
      src: publish_instagram,
    },
    {
      id: 4,
      name: 'linkedin',
      title: 'Publish to LinkedIn',
      description: '10 min limit. Recommend a 30 sec to 2 min snippet in landscape.',
      commingSoon: true,
      color: '#0077B5',
      src: publish_linkedin,
    },
    {
      id: 5,
      name: 'twitter',
      title: 'Publish to Twitter',
      description: '140 sec limit. Recommend a 30 sec to 1 min snippet in landscape.',
      commingSoon: true,
      color: '#00acee',
      src: publish_twitter,
    },
  ]

  INGESTION_CHANNELS = {
    'Convert webinar into interactive audio': [{ id: 1, text: 'Coming soon' }],
    'Convert YouTube video into interactive audio': [{ id: 2, text: 'Coming soon' }],
    'Convert Medium post into interactive audio': [{ id: 3, text: 'Coming soon' }],
  }

  selectedChannelId: any = null
  showYoutubeVideo: any = false

  setSelectedChannelId(id: number) {
    this.selectedChannelId = id
    this.showYoutubeVideo = !this.showYoutubeVideo
  }

  handleApps(app: String) {}
  async mounted() {
    this.$store.commit('setStep', 1)
    if (this.networkId !== null) await this.getIntegrationsSummary({ networkId: this.networkId })
  }
}
