


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import PublishComingSoon from '@/components/Publish/PublishComingSoon.vue'
import AllPodcastsPage from '@/components/Podcast/AllPodcastsPage.vue'
import OverviewBanner from '@/components/Overview/OverviewBanner.vue'
import PublishInfoCard from '@/components/Publish/EmptyState/PublishInfoCard.vue'
import banner_no_podcasts from '@/assets/no-podcasts.svg'
import { SetupContext } from '@vue/composition-api'
import { useFetchRssFeed } from '@/hooks/rssFeeds'
import get from 'lodash.get'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    PublishComingSoon,
    AllPodcastsPage,
    OverviewBanner,
    PublishInfoCard,
  },
  setup(_, context: SetupContext) {
    const { data: rssFeedsData, isFetching: rssFeedLoading } = useFetchRssFeed({}, false, (data: any) => {
      context.root.$store.commit('setRssFeeds', data.data)
      context.root.$store.commit('setRssFeedsCount', data.count)
      context.root.$store.commit('setRssFeedsOffset', data.count)
      data?.data.map((rssFeed: any) =>
        context.root.$store.commit('addRssFeed', {
          rssFeed,
          rssFeedItems: [],
        })
      )
      context.root.$store.commit('setRssFeedUids')
    })

    return {
      rssFeedsData,
      rssFeedLoading,
    }
  },
})
export default class ViewPublish extends Vue {
  @Action showImportPodcast!: any
  @Action showCreatePodcast!: any
  @Action setSelectedRssFeedUid!: any

  rssFeedsData!: any
  rssFeedLoading!: any

  bannerHeaderPublish: string = 'Publish a podcast in 3 steps'
  bannerSubHeaderPublish: string =
    'Manage all your podcasts in one place, and publish your show in all major podcast directories to maximize reach. Add new episodes immediately or schedule them for publishing at a later date.'
  bannerImageUrlPublish: string = banner_no_podcasts

  selectedRssFeed!: string

  get rssFeedsCount() {
    return get(this.rssFeedsData, 'count', 0)
  }

  get selectedPodcast() {
    const uid = this.$route.params.id
    return uid
      ? this.rssFeedsData?.rssFeedByUid[this.selectedRssFeed] || this.rssFeedsData?.data[0]
      : this.rssFeedsData?.data[0]
  }

  handleRoutes() {
    const channel = this.$route.params.channel
    const uid = this.$route.params.id
    if (channel === 'podcasts' && !uid) {
      this.selectedRssFeed = this.rssFeedsData.data[0]?.uid
      this.setSelectedRssFeedUid(this.selectedRssFeed)
      this.$router.push(`/publish/podcasts/${this.selectedRssFeed}`)
    } else if (channel === 'podcasts' && uid) {
      this.selectedRssFeed = uid
      this.setSelectedRssFeedUid(uid)
    }
    // @ts-ignore
    this.$refs?.allPodcastPage.selectRssFeedUid(this.selectedRssFeed)
  }

  created() {
    this.handleRoutes()
    this.$watch(
      () => this.$route,
      () => {
        this.handleRoutes()
      }
    )
  }

  selectRss(feedUid: string) {
    this.selectedRssFeed = feedUid
    this.setSelectedRssFeedUid(feedUid)
    this.$router.push(`/publish/podcasts/${feedUid}`)
  }

  handleImportPodcast() {
    this.showImportPodcast()
  }

  handleCreatePodcast() {
    this.showCreatePodcast()
  }
}
