var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c(
            "div",
            { staticClass: "limiter scrollbar" },
            [
              _c("TheTitle", { attrs: { icon: "publish", title: "Publish" } }, [
                _c(
                  "div",
                  {
                    staticClass: "flex",
                    attrs: { slot: "left" },
                    slot: "left",
                  },
                  [
                    _vm.$permissions.isViewPodcastAllowed()
                      ? _c("div", { staticClass: "flex items-end mr4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "fw3 f5 dib link glow pt3 pb1 pointer light-gray bb b--white bw1",
                            },
                            [_vm._v("Podcasts")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "f7 bg-adori-very-light-gray br3 white ml1",
                              staticStyle: {
                                padding: "2px 5px",
                                "margin-bottom": "6px",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.rssFeedsCount) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c("div", { attrs: { slot: "right" }, slot: "right" }, [
                  _c("div", { staticClass: "w-100 pt1" }, [
                    _vm.$permissions.isCreatePodcastAllowed()
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "flex justify-between items-center light-gray",
                          },
                          [
                            _vm.rssFeedsCount !== 0
                              ? _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("BaseButtonBorder", {
                                      staticClass: "mr2",
                                      attrs: {
                                        text: "Transfer existing podcast",
                                        onClick: _vm.handleImportPodcast,
                                      },
                                    }),
                                    _c("BaseButtonBorder", {
                                      attrs: {
                                        text: "Create new podcast",
                                        onClick: _vm.handleCreatePodcast,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm.rssFeedLoading
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-column justify-center items-center",
                    },
                    [
                      _c("div", {
                        staticClass:
                          "bg-adori-medium-gray br3 w-100 skeleton top-size",
                      }),
                      _c("div", {
                        staticClass:
                          "bg-adori-medium-gray mt1 br3 w-100 skeleton bottom-size",
                      }),
                    ]
                  )
                : _c(
                    "div",
                    [
                      _vm.rssFeedsCount === 0
                        ? _c(
                            "div",
                            [
                              _c("OverviewBanner", {
                                attrs: {
                                  isLargeBanner: "",
                                  bannerHeader: _vm.bannerHeaderPublish,
                                  bannerSubHeader: _vm.bannerSubHeaderPublish,
                                  bannerImageUrl: _vm.bannerImageUrlPublish,
                                  bannerLocation: "ALL_PUBLISH",
                                },
                              }),
                              _c("PublishInfoCard", {
                                attrs: { isColumn: "" },
                              }),
                            ],
                            1
                          )
                        : _c("AllPodcastsPage", {
                            ref: "allPodcastPage",
                            attrs: {
                              isPublishPage: "",
                              showPreviewButton: "",
                              editable: "",
                              selectedPodcast: _vm.selectedPodcast,
                              rssFeedsData: _vm.rssFeedsData.data,
                            },
                            on: { selectRss: _vm.selectRss },
                          }),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }